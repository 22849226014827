<script setup>
import {computed} from 'vue';

const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        type: String,
        default: null,
    },
    title: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: '',
    }
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('update:checked', val);
    },
});
const id = `checkbox-${Math.random().toString(36).slice(2, 9)}`
</script>

<template>
    <label class="container">
        <input
            :id="id"
            v-model="proxyChecked"
            type="checkbox"
            :value="value"
            :name="name"
        >
            <slot name="label"/>

        <span class="checkmark"></span>
    </label>
</template>

<style>
.container {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: fit-content;
}

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    height: 17px;
    width: 17px;
    background-color: transparent;
    outline: 2px solid #9CA3AF;
    border-radius: 3px;
}

.container input:checked ~ .checkmark {
    background-color: #2563EB;
    outline: none;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    @apply dark:border-dark-blue;
}
</style>
